<template>
    <div class="h-min-content w-full">
        <ComplaintsSampleTable />
    </div>
</template>

<script>
import ComplaintsSampleTable from '@/components/Dashboard/Complaints/Samples/ComplaintsSampleTable.vue'
export default {
  components: {
    ComplaintsSampleTable
  }
}
</script>

<style>

</style>