<template>
  <div class="p-6 bg-white rounded-xl shadow-lg mx-4 w-4/5 mb-4">
    <h2 class="text-left text-md text-gray-600">
      <b>Estado de monitoreo actual</b>
    </h2>
    <div class="flex flex-row">
      <div class="w-1/2 text-left">
        <p class="text-md text-gray-600">
          <b class="text-md text-blue-600">{{
            `${sample.progress}/${sample.total} muestras`
          }}</b>
          han sido monitoreadas
        </p>
        <a-progress
          :percent="sample.percent"
          :strokeWidth="15"
          strokeColor="green"
          strokeLinecap="square"
        />
      </div>
      <div class="w-1/2 text-right">
        <a-menu mode="horizontal">
        <a-sub-menu>
          <a-button
            slot="title"
            type="primary"
            style="margin-bottom: 16px"
            @click="collapsed = !collapsed"
          >
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
          </a-button>
          <a-menu-item
            ><vue-excel-xlsx
              :loading="loading"
              :data="actual"
              :columns="columnsExcel"
              :file-name="'Listado muestra '+sample.criterion_name"
              sheetName="Listado"
            >
              <span class="mx-2">Descargar vista actual XLSX</span>
            </vue-excel-xlsx></a-menu-item
          >
          <a-menu-item @click="onGetAllComplaints(false)">
            <vue-excel-xlsx
              :async="true"
              :loading="loading"
              :data="jsondata"
              :columns="columnsExcel"
              :file-name="'Listado muestra '+sample.criterion_name+'(Completo)'"
              sheetName="Listado"
            >
              <span class="mx-2">Descargar reporte completo XLSX</span>
            </vue-excel-xlsx>
          </a-menu-item>
          <a-menu-item @click="onGetAllComplaints(true)">
            <vue-excel-xlsx
              :async="true"
              :loading="loading"
              :data="jsoncsv"
              :columns="columnsExcel"
              file-type="csv"
              :file-name="'Listado muestra '+sample.criterion_name+'(Completo)'"
              sheetName="Listado"
            >
              <span class="mx-2">Descargar reporte completo CSV</span>
            </vue-excel-xlsx></a-menu-item
          >
        </a-sub-menu>
      </a-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sample", "jsondata", "jsoncsv", "actual", "loading"],
  data() {
    return {
      sample_per: 0,
      pageSizeDownload: 10000,
      columnsExcel: [
        { label: "Código de la Queja", field: "codigo_queja" },
        { label: "Número de Identificación", field: "numero_id_CF" },
        { label: "Nombre", field: "nombres" },
        { label: "Entidad", field: "entity_name" },
        { label: "Estado", field: "complaint_state" },
        { label: "Evaluado", field: "revised_name" },
        { label: "Fecha de Creación", field: "fecha_creacion" },
      ],
      collapsed: false,
    };
  },
  created() {
    const sample = this.$props.sample;
    this.sample_per = (sample.progress / sample.total).toFixed(2) + " muestras";
  },
  methods: {
    onGetAllComplaints(isCsv = false) {
        this.$emit("download-report-samples", isCsv);
    },
  },
};
</script>

<style>
</style>